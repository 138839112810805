import React from 'react'
import Layout from '../components/Layout'
import PageHero from '../components/PageHero'
import styled from 'styled-components'
import GoogleMaps from '../components/GoogleMaps'
import { Link } from 'gatsby'

const assets = {
  support: require('../resources/img/support_1.png'),
  support_email: require('../resources/img/support_email.svg'),
  support_sales: require('../resources/img/support_sales.svg'),
  support_call: require('../resources/img/support_call.svg'),
  support_whatsapp: require('../resources/img/support_whatsapp.svg')
}

const googleMaps = {
  key: 'AIzaSyCJsxZ_zz2YQU0ZvKnqX414t_NVa8udFoQ',
  lat: '12.58109',
  lng: '-7.9931087',
}


export default () => (
  <Layout page="support">

    <PageHero title="Support & Contact"
              content={
                <div>
                  L'équipe Vitepay est à votre disposition par email ou téléphone pour répondre à vos questions.
                </div>
              }/>

    <div className="container text-center" style={{ marginTop: -50 }}>
      <div className="clearfix" style={{ margin: '0 -50px' }}>
        <img src={assets.support} alt="" className="img-fluid"/>
      </div>
    </div>

    <p>&nbsp;</p>


    <div className="text-center mt-5">
      <h2>Questions fréquemment posées</h2>
      <p className="lead">Peut-être que la réponse à votre question se trouve dans cette liste</p>
    </div>

    <p>&nbsp;</p>

    <div className="clearfix container mt-5 mb-5">

      <FAQ className="row  circle-bg-grey" style={{ backgroundPosition: '100% 0' }}>
        <div className="col-6">
          <h3>Est-ce que Vitepay est présent dans mon pays ?</h3>
          <p>
            Les services Vitepay ne sont actuellement présents qu'au <strong>Mali</strong>. Nous restons toutefois
            ouverts à des collaborations
            pour étendre notre activité dans d'autres pays. Si vous pensez pouvoir aider dans ce sens, n'hésitez à
            nous <Link to="/contact">contacter</Link>.
          </p>
        </div>
        <div className="col-6">
          <h3>Combien ça coûte Vitepay ?</h3>
          <p>
            Vitepay propose plusieurs services autour du e-commerce. Chaque service est adossé à un prix. Pour en savoir
            plus, nous vous invitons à
            consulter notre rubrique <Link to="/pricing">combien ça coûte</Link>.
          </p>
        </div>
        <div className="col-6">
          <h3>Quelle est la durée minimum d'engagement ?</h3>
          <p>
            Aucune. Vous pouvez arrêter d'utiliser les services Vitepay à tout moment, sans frais.
          </p>
        </div>
        <div className="col-6">
          <h3>Quand est-ce que je reçois mon argent ?</h3>
          <p>
            Lorsqu'un client effectue un achat sur un site marchand, nous bloquons le montant pendant 7 jours pour
            permettre le règlement
            à l'amiable d'éventuel litige. Après ces 7 jours, votre balance est créditée du montant de la transaction.
            Vous pouvez demander
            à tout moment à Vitepay de vous réglerer par chèque, virement ou mobile money.
          </p>
        </div>
        <div className="col-6">
          <h3>2% sur chaque transaction c'est cher, non ?</h3>
          <p>
            Dans les 2% une partie revient à l'opérateur téléphonique, en charge de l'infrastructure Mobile Money. Nous
            considérons qu'il s'agit
            là d'un montant très correct d'autant plus que Vitepay permet aux marchands de toucher encore plus que
            clients.
          </p>
        </div>
        <div className="col-6">
          <h3>Quelles sont les conditions de remboursement ?</h3>
          <p>
            Lorsque vous effectuer un paiement à travers le service Vitepay, nous conservons votre argent pendant 7
            jours.
            Durant cette période, vous pouvez contacter le marchand auprès duquel vous avez effectué votre achat pour
            formuler votre
            demande de remboursement. L'équipe Vitepay peut intervenir en tant que médiateur. Si toutes les conditions
            sont réunies
            pour effectuer le remboursement, vous recevez l'intégralité du montant.<br/>
            <strong>Au delà de ces 7 jours</strong> tout litige doit être réglé directement entre vous et le site
            marchand.
          </p>
        </div>
      </FAQ>
    </div>

    <div className="text-center mt-5" id="contact">
      <h2>Nous Contacter</h2>
    </div>

    <p>&nbsp;</p>

    <div className="container">
      <GoogleMaps mapsKey={googleMaps.key} address={'Logineo'} zoom={17}/>
      <p>&nbsp;</p>
    </div>

    <div className="clearfix " style={{ background: '#F6F7F8' }}>
      <div className="container">
        <div className="row">
          <div className="col-4">
            <ContactBlock>
              <img src={assets.support_sales} alt=""/>
              <h4>Sevice Commercial</h4>
              <p>
                Notre équipe commercial es à votre disposition pour vous aider à bien démarrer
              </p>
              <Link to="/contact/commercial">
                Contactez le service commercial
              </Link>
            </ContactBlock>
          </div>
          <div className="col-4">
            <ContactBlock>
              <img src={assets.support_email} alt=""/>
              <h4>Support Email</h4>
              <p>
                Vous n'avez pas trouvé réponse à vos questions dans la foire aux questions ?
                Contactez-nous par email.
              </p>
              <Link to="contact/support">
                Contactez le support
              </Link>
            </ContactBlock>
          </div>
          <div className="col-4">
            <ContactBlock>
              <img src={assets.support_whatsapp} alt=""/>
              <h4>WhatsApp</h4>
              <p>
                Pour les cas où une communication instannée est plus adaptée, retrouvez-nous
                sur WhatsApp.
              </p>
              <strong>XX-XX-XXX-XXX</strong>
            </ContactBlock>
          </div>
        </div>
      </div>
    </div>

  </Layout>
)


const ContactBlock = styled.div`
  background: #FFF;
  border-radius: 6px;
  padding: 30px;
  box-shadow: 0px 1px 6px rgba(0,0,0,0.05);
  margin-top: 60px;
  margin-bottom: 60px;

  img {
    width: 48px;
    margin-bottom: 16px;
  }
  h4 {
    margin-bottom: 15px;
  }
  
  p {
    color: #565758;
    font-size: 0.95em;
  }
  
  a {
    font-size: 0.9em;
  }
`

const FAQ = styled.div` 
  h3 {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    color: rgba(26,26,26,.9);
    font-size: 13px;
  }
  
  p {
    color: #5c5c5c;
    padding: 20px 30px 20px 0;  
    text-align: justify;
    
    a {
      font-weight: 500;
    }
    
    strong {
      font-weight: 500;
    }
    
  }
  
`
