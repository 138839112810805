import React from 'react'

export default ({mapsKey, address, zoom}) => (
  <iframe
    width="100%"
    height="450"
    title="Contacter Vitepay"
    frameBorder="0" style={{border: 0}}
    src={`https://www.google.com/maps/embed/v1/place?key=${mapsKey}&q=${ address }&zoom=${zoom || 17}`}
    allowFullScreen>
  </iframe>
)
